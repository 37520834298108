.user-top {
  color: #000;

  .circle-container {
    background-color: #aed6ac;
    max-width: 100%;
    font-family: '游ゴシック体', '游ゴシック', 'Yu Gothic', '游ゴシック体',
      YuGothic, sans-serif;

    .username {
      padding-top: 3rem;
      text-align: center;
      .title {
        font-size: 32px;
        color: #ffffff;
        letter-spacing: 0.2rem;
      }
    }

    .my-score {
      margin-top: 2rem;

      .my-score-graphs {
        width: 80%;
        margin: 0 auto;

        .graph {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .category-container {
    margin-top: 2rem;
  }

  .new-videos,
  .popular-videos {
    margin-bottom: 2.6rem;
  }

  .my-score-descriptions {
    display: flex;
    justify-content: center;
    .total {
      display: inline-flex;
      margin: 0 2rem;

      .bar {
        border-radius: 2px;
        height: 1em;
        width: 4em;
        margin-top: 0.25rem;
        margin-right: 0.5rem;
      }

      .text {
        font-weight: bold;
      }

      &.exe {
        .bar {
          border-bottom: 1em solid #e55e59;
        }
        .text {
          color: #e55e59;
        }
      }

      &.cal {
        .bar {
          border-bottom: 1em solid #b59fce;
        }
        .text {
          color: #b59fce;
        }
      }
    }
  }
}
