.video-values {
  & > .col {
    text-align: center;
    &.time {
      color: #d43474;
    }
    &.strength {
      color: #000000;
    }
    &.time::after,
    &.strength::after {
      content: '';
      background-color: #8898aa;
      position: absolute;
      height: 60%;
      width: 1px;
      top: 20%;
      right: 0;
    }
    &.kcal {
      color: #6cd0dc;
    }
    & > .value {
      margin-top: 0.4rem;
      font-size: 1.5em;
      .suffix {
        font-size: 0.8em;
      }
    }
    & > div > .fa {
      margin-right: 0.4rem;
    }
  }
}
