.video-card {
  width: 20rem;

  &.card {
    border: none;
    box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 0.5);
    &:hover {
      box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 1);
    }
  }

  .box {
    background-color: #cccccc;
    width: 100%;
    height: 200px;
    margin: 0px auto;
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
  }

  .body {
    .title {
      padding: 0.8rem 1rem 0.4rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tags-kcal {
      display: flex;

      .tags {
        width: calc(100% - 5rem);
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 1rem 0.4rem;
        & > * {
          color: #5d6c6d;
          font-size: 0.8rem;
          margin-left: 0.4rem;
          &::before {
            content: '#';
          }
        }
      }

      .kcal {
        width: 5rem;
        position: relative;
        font-size: 0.88rem;
        & > span {
          position: absolute;
          right: 0.8rem;
          bottom: 0.4rem;
          &::after {
            content: 'kcal';
          }
        }
      }
    }
  }
}
