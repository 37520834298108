.overlay-card {
  height: 100%;
  border: unset;
  background-clip: unset;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 0.5);
  &:hover {
    box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 1);
  }

  &.small-card {
    .no-image {
      height: 2.6rem;
    }
    .content > .title {
      color: #000;
      font-size: 95%;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    filter: brightness(80%) blur(1px);
    object-fit: cover;
  }

  .no-image {
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
  }

  &.details {
    .no-image {
      width: 100%;
      padding-top: 100%;
      background-color: #cccccc;
      filter: blur(1px);
    }
  }

  .content {
    width: 100%;
    text-align: center;
    top: 50%;
    right: unset;
    bottom: unset;
    left: unset;
    transform: translateY(-50%);

    .title {
      font-size: 180%;
      &::before {
        content: '#';
      }
    }
  }
}
