.loading-spinner-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  & > .loading-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 8rem;
    height: 8rem;
    margin: auto;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: loading-spin 1.5s linear infinite;
    @keyframes loading-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
