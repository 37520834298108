.common-worry {
  margin-bottom: 2.6rem;

  .worry-cards-container {
    .overlay-card-container {
      padding-top: 0.6rem;
      padding-right: 0.6rem;
      padding-left: 0rem;
    }
  }
  .overlay-card {
    width: 100%;
    height: 100%;
  }
}
