.user-worries {
  color: #000;

  .content {
    margin-bottom: 5rem;
    .title {
      margin: 2rem 0 1.6rem 0;
    }
    .overlay-card-container {
      padding-top: 0.6rem;
      padding-right: 0.6rem;
      padding-left: 0rem;
    }
  }
}
