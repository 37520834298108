.show-all-card {
  color: #000;
  width: 98%;
  height: 16rem;
  position: relative;
  border-radius: 0.375rem;
  box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 0.5);
  &:hover {
    box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 1);
  }

  .card-text {
    position: absolute;
    bottom: 12%;
    left: 16%;
  }

  .card-arrow {
    position: absolute;
    bottom: 12%;
    right: 20%;
  }
}
