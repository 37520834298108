.cant-login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .title {
    text-align: center;

    & > span {
      display: block;
      @media (min-width: 576px) {
        display: inline-block;
      }
    }
  }
}
