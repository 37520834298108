.wide-video-card {
  $radius: 16px;

  background-color: #fdfdfd;
  border-radius: $radius;
  box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 0.5);
  &:hover {
    box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 1);
  }
  @media (max-width: 991px) {
    padding-bottom: 1rem;
  }

  & > .row > .col {
    & > .image {
      width: 100%;
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
      @media (max-width: 991px) {
        border-bottom-left-radius: 0px;
      }
      @media (max-width: 767px) {
        border-top-right-radius: $radius;
      }
    }

    & > .title {
      font-weight: bold;
      font-size: 1.1rem;
      margin-top: 0.8rem;
      @media (max-width: 767px) {
        margin-left: 1rem;
      }
    }

    & > .tags {
      width: 92%;
      overflow-x: auto;
      white-space: nowrap;
      display: flex;
      position: absolute;
      bottom: 0.8rem;
      font-size: 0.9rem;
      @media (max-width: 767px) {
        overflow-x: unset;
        white-space: unset;
        flex-wrap: wrap;
        position: unset;
        bottom: unset;
        margin-top: 0.6rem;
        margin-left: 1rem;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      & > .tag {
        &:not(:last-child) {
          margin-right: 0.6rem;
        }
        &::before {
          content: '#';
        }
      }
    }

    & > .video-values {
      font-size: 84%;
      height: 100%;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        margin-top: 0.7rem;
      }
    }
  }
}
