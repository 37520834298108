.price-label {
  font-size: 2.2rem;
  padding: 0px;
  margin: 0px;
}

.price-label-12 {
  color: rgb(239, 111, 171);
}
.price-label-month {
  color: rgb(144, 253, 253);
}

.price-plan-space {
  padding-left: 3.75rem;
}

.custom-radio .custom-control-input ~ .custom-control-label {
  cursor: pointer;
  font-size: 0.875rem;
  top: 5rem;
}
