.video-list {
  .result-count {
    font-size: 0.9em;
  }
  .no-video-text {
    text-align: center;
    margin: 1.8rem 0;
  }
  .video-card-container {
    width: auto;
    margin-top: 0.4rem;

    .video-card {
      width: auto;
    }
  }
}
