.genre-card {
  .genre-link {
    .genre {
      & > img {
        width: 100%;
        border-radius: calc(0.375rem - 1px);
        box-shadow: 0 3px 10px -2px rgba(0, 10, 60, 0.1);
        &:hover {
          box-shadow: 0 3px 10px -2px rgba(0, 10, 60, 0.2);
        }
      }

      & > h3 {
        margin-top: 0.4rem;
        padding-left: 0.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > .box {
        background-color: #cccccc;
        width: 100%;
        height: 250px;
        border-radius: calc(0.375rem - 1px);
        box-shadow: 0 3px 10px -2px rgba(0, 10, 60, 0.1);
        &:hover {
          box-shadow: 0 3px 10px -2px rgba(0, 10, 60, 0.2);
        }
      }
    }
  }
}
