.popular-exercises {
  margin-bottom: 2.6rem;

  .popular-exercises-container {
    padding: 0.8rem 0 1rem 0;

    .exercises-link {
      .exercises {
        & > img {
          width: 100%;
          border-radius: 10px;
          box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 0.5);
          &:hover {
            box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 1);
          }
        }
        & > h3 {
          margin-top: 0.4rem;
          padding-left: 0.4rem;
        }

        .box {
          background-color: #cccccc;
          width: 100%;
          height: 36vh;
          margin: 0px auto;
          border-radius: 10px;
          box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 0.5);
          &:hover {
            box-shadow: 0 20px 30px -15px rgba(185, 201, 201, 1);
          }
        }
      }
    }
  }
}
