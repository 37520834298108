.set-profile {
  padding: 6rem 0;
  text-align: center;
  .profile {
    font-family: '游ゴシック体', '游ゴシック', 'Yu Gothic', '游ゴシック体',
      YuGothic, sans-serif;
    color: #ffffff;
  }
  .btn-outline-danger {
    margin-top: 2rem;
    padding: 0.8rem 2rem;
    border-radius: 50px;
    color: #d23474;
    border-color: #d23474;
  }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d23474;
    border-color: #d23474;
    box-shadow: none;
  }
  .btn-outline-danger:focus,
  .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(210, 52, 116, 0.5);
  }
  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #d23474;
    background-color: transparent;
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d23474;
    border-color: #d23474;
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(210, 52, 116, 0.5);
  }
}
