.recommend-courses {
  margin-bottom: 2.6rem;

  .recommend-courses-container {
    padding: 0.8rem 0 1rem 0;

    .course-link {
      .course {
        & > img {
          width: 100%;
          border-radius: 10px;
        }
        & > h3 {
          margin-top: 0.4rem;
          padding-left: 0.4rem;
        }

        .box {
          background-color: #cccccc;
          width: 100%;
          height: 36vh;
          margin: 0 auto;
          border-radius: 10px;
        }
      }
    }
  }
}
