.category-badge {
  color: unset;

  .badge {
    color: inherit;
    background-color: #e9ecef;
    line-height: normal;
    font-size: 80%;
    font-weight: normal;
    padding: 0.45em 1.6em;
  }
}
