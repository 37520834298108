.user-view-history > .container {
  color: #000;

  margin-bottom: 4rem;
  & > .title {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  & > .date {
    margin-bottom: 0.7rem;
    padding-top: 1rem;
    font-size: 0.95rem;
    color: #707070;
  }
  & > .view-history-card-link {
    color: unset;
    & > .view-history-card {
      margin-bottom: 1.16rem;
    }
  }
}
