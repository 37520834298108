.responsive-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  & table {
    table-layout: auto !important;
    .filter-label {
      display: block;
      .sr-only {
        display: none;
      }
    }
  }
}
