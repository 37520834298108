.user-video-detail {
  color: #000;
  .category-badge {
    margin-top: 2rem;
    border-radius: 5px;
  }

  .video-title {
    margin-top: 1rem;
  }

  .video-container {
    margin: 1.5rem 0;
    & > .youtube-video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      & > iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .video-values {
    margin: 2.2rem 0;
  }

  .overview {
    .content {
      padding-left: 1rem;
      .tags {
        margin-top: 1em;
      }
      .body-part-tags {
        margin-top: 2rem;
      }
      .body-part-badge {
        margin: 0 0.4rem;
      }
    }
  }

  .next-step-video {
    margin-top: 2rem;
    .next-step-video-link {
      color: unset;
      .video-values {
        margin: 0;
      }
    }
  }

  .same-category-videos {
    margin-top: 2rem;
    .title {
      margin-bottom: 0.8rem;
    }
    .video-card {
      width: auto;
      margin-bottom: 1rem;
    }
  }

  .common-worry {
    margin-top: 1rem;
    margin-bottom: 3rem;
    .overlay-card-container {
      padding-top: 0.6rem;
      padding-right: 0.6rem;
      padding-left: 0rem;
    }
  }
}
