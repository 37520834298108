.user-genre-list {
  color: #000;

  .categories {
    margin-top: 2.4rem;
  }
  .content {
    margin-bottom: 5rem;
    .title {
      margin: 2rem 0 1.6rem 0;
    }
  }
}
