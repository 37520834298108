.interest-parts {
  margin-bottom: 2.6rem;

  .interest-parts-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  --item-space: 0.8rem;
  .row {
    margin-right: calc(0 - var(--item-space) / 2);
    margin-left: calc(0 - var(--item-space) / 2);
  }

  .col-6,
  .col-md-3 {
    padding-right: calc(var(--item-space) / 2);
    padding-left: calc(var(--item-space) / 2);
  }

  .interest-part-link {
    color: unset;

    .interest-part {
      margin-top: var(--item-space);
      position: relative;

      .image {
        width: 100%;
        height: 5rem;
        object-fit: cover;
        border-radius: 12px;
      }
      .title {
        vertical-align: middle;
        font-size: 1.4rem;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
