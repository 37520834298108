.fixedOperationCol > .react-bootstrap-table table {
  border-collapse: separate;
  border-spacing: 0;
}
.fixedOperationCol
  > .react-bootstrap-table
  table
  > tbody
  > tr
  > td:nth-last-child(1),
.fixedOperationCol
  > .react-bootstrap-table
  table
  > thead
  > tr:nth-last-child(1)
  > th:nth-last-child(1) {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  z-index: 1;
  border-left-width: 3px;
  border-right-width: 3px;
  border-left-color: #dcd9d6;
}
.fixedOperationCol
  > .react-bootstrap-table
  table
  > thead
  > tr:nth-last-child(1)
  > th:nth-last-child(1),
.fixedOperationCol
  > .react-bootstrap-table
  table
  > tbody
  > tr:nth-child(even)
  > td:nth-last-child(1) {
  background-color: white;
}
.fixedOperationCol
  > .react-bootstrap-table
  table
  > tbody
  > tr:nth-child(odd)
  > td:nth-last-child(1) {
  background-color: #f2f2f2;
}
