.choose-category {
  display: flex;
  line-height: 2;

  .title {
    margin: 0.3em 0;
    width: 10.5em;
    text-align: center;
    border-right: 2px solid #8898aa;
  }

  .badges {
    width: calc(100% - 10.5em);
    margin-left: 0.8em;
    overflow-x: auto;
    white-space: nowrap;

    .choose-category-badge {
      margin: 0 0.4em;
    }
  }
}
