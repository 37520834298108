.header {
  .header-avatar {
    font-size: 1.5rem;
    height: 1.5rem;
    color: #e61974;
  }
}

.user_header {
  border-bottom: 1px solid #adb5bd;
}
